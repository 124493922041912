import '@popperjs/core/lib/popper.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import "bootstrap-icons/font/bootstrap-icons.css"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'quill/dist/quill.snow.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import { getSsoLogoutUrl } from "@/services/sso.service";
import { emitter } from "@/services/emitter.service";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('bearer') != null) {
            config.headers.authorization = `Bearer ${localStorage.getItem('bearer')}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

axios.interceptors.response.use(response => {
    return response;
}, error => {
    // Go back to login page when not authenticated
    if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace(getSsoLogoutUrl());
    }
    else if (error.response.status === 403) {
        emitter.emit("showLoader", false);
        alert("You don`t have permission to perform this action.");
    }
    return Promise.reject(error);
});

createApp(App).use(router).mount('#app')
